<template>
  <div>
    <h1>Privacy Statement</h1>
    <p><strong>Privacy Statement for murshisoft.com</strong></p>
    <p>
      MurshiSoft LLP has created this privacy statement in order to demonstrate
      our company commitment to privacy. The following discloses our information
      gathering and dissemination practices for this website: easyprojects.net
    </p>
    <p>
      <strong>Collection of Personal Information from Service Visitors.</strong>
    </p>
    <p>
      MurshiSoft LLP may collect and/or track (1) the home server domain names,
      email addresses, type of client computer, files downloaded, search engine
      used, operating system, and type of web browser of visitors to MurshiSoft
      LLP web service, (2) the email addresses of visitors that communicate with
      MurshiSoft LLP via email, (3) information knowingly provided by the
      visitor in online forms, registration forms, surveys, email, contest
      entries, and other online avenues (including demographic and personal
      profile data), and (4) aggregate and user-specific information on which
      pages visitors access.
    </p>
    <p>
      MurshiSoft LLP may place Internet “cookies” on visitors’ hard drives.
      Internet cookies save data about individual visitors, such as the
      organization’s name, password, user-name, screen preferences, and the
      pages of a service viewed by the visitor. When the visitor
      revisitsMurshiSoft LLP web service, MurshiSoft LLP may recognize the
      visitor by the Internet cookie and customize the visitor’s experience
      accordingly. Visitors may decline Internet cookies, if any, by using the
      appropriate feature of their web client software, if available.
    </p>
    <p><strong>Use of Personal Data Collected.</strong></p>
    <p>
      Personal data collected by MurshiSoft LLP may be used by MurshiSoft LLP
      for editorial and feedback purposes, for marketing and promotional
      purposes, for a statistical analysis of users’ behavior, for product
      development, for content improvement, or to customize the content and
      layout of MurshiSoft LLP service. Aggregate data on visitors’ home servers
      may be used for internal purposes but will not be provided to third
      parties such as marketing firms. Individually identifying information,
      such as names, postal and email addresses, phone numbers, and other
      personal information which visitors voluntarily provide to MurshiSoft LLP
      may be added to MurshiSoft LLP databases and used for future calls and
      mailings regarding service updates, new products and services, and
      upcoming events.
    </p>
    <p>
      Our company use client’s information from 30-day Trial Registration form.
      We keep customers contact information strictly confidential and use it
      only to send important information to our customers.
    </p>
    <p><strong>Security Measures.</strong></p>
    <p>
      MurshiSoft LLP has implemented numerous security features to prevent the
      unauthorized release of or access to personal information. For example,
      all MurshiSoft LLP employees are required to certify their understanding
      that personal information is considered confidential, that it is important
      to safeguard personal information, and that MurshiSoft LLP will take
      appropriate action against any employee who fails to acknowledge these
      facts or adhere to the requisite standards of conduct. Please be advised,
      that MurshiSoft LLP is not responsible for the security of information
      transmitted via the Internet (electronic mail services) . For more private
      communication contact MurshiSoft LLP by telephone at +91 (735) 698 9449.
    </p>
    <p><strong>MurshiSoft LLP Right to Contact Users.</strong></p>
    <p>
      MurshiSoft LLP reserves the right to contact service visitors regarding
      sales and other informational requests made through its web service.
    </p>
    <p><strong>MurshiSoft LLP Right to Change Privacy Policy.</strong></p>
    <p>
      MurshiSoft LLP reserves the right to change this Privacy Policy at any
      time without notice. Any change to this Privacy Policy shall be effective
      as to any visitor that has accepted the easyprojects.net Service Terms and
      Conditions before the change was made.
    </p>
    <p><strong>Contact Information</strong></p>
    <p>
      MurshiSoft LLP welcomes your comments regarding this Statement of Privacy,
      please contact us by e-mail, or postal mail.
    </p>
    <dl>
      <dt>&nbsp;</dt>
      <dd>MurshiSoft LLP</dd>
      <dt>&nbsp;</dt>
      <dd>VII/42 Paloth Poovathikkal</dd>
      <dt>&nbsp;</dt>
      <dd>Malappuram, Kerala</dd>
      <dt>&nbsp;</dt>
      <dd>673639 India</dd>
    </dl>
  </div>
</template>

<script>
export default {};
</script>

<style></style>
